import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import ButtonAuth from '../../components/ButtonAuth';
import Divider from '../../components/Divider';
import useDeviceType from '../../utils/useDeviceType';
import { useNavigate } from 'react-router';

import './styles.css'
import GoogleButton from "../../components/GoogleButton";
type Login = {
  email: string,
  password: string
}

const Login = ({ setIsAuth }) => {
  const deviceType = useDeviceType()
  const navigate = useNavigate()
  const [login, setLogin] = useState<Login>({
    email: '',
    password: ''
  })
  const [isError, setIsError] = useState<boolean>(false)


  const pb = new PocketBase('https://auth.dillo.ar/');

  const handleOnLoginClick = async() => {
    const authData = await pb.collection('users').authWithPassword(
      login.email.toLowerCase(),
      login.password,
    )
    .then( data => {
      setIsError(false)
      setIsAuth(pb.authStore.isValid)
      navigate('/')
      
    })
    .catch(error => {
      console.log(error)
      setIsError(true)
    });
  }

  const handleOnOAuthClick = async (provider) => {
    const w = window.open()
    const authData = await pb.collection('users').authWithOAuth2({ provider: provider, urlCallback: (url) => {
        w.location.href = url
      } });
    setIsAuth(pb.authStore.isValid)
    navigate('/')
  }

  const handleOnChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnLoginClick();
    }
  }

  return (
    <div className='login-container' >
        <div className='logo-container'>
          <img 
            src="/dillo-brand.png"
            width='192px'
          />
        </div>
      <h1 className="title">Bienvenido</h1>
        <div className='form-container'>
          <div className={`${deviceType !== 'mobile' ? 'info-container-desktop': 'info-container' }`}>
            <Input 
              type='text'
              name='email'
              handleOnChange={(e) => handleOnChange(e)}
              value={login.email}
              label='Usuario'
              handleKeyDown={handleKeyDown}
            />
            <Input 
              type='password' 
              name='password' 
              handleOnChange={(e) => handleOnChange(e)} 
              value={login.password}
              label='Contraseña'
              handleKeyDown={handleKeyDown}
            />
            <ButtonAuth 
              handleOnClick={() => handleOnLoginClick()}
              buttonText='Entrar a Dillo'
            /> 
            {isError && <label className="error-message">Usuario y/o contraseña invalido</label>}
          </div>
          <Divider label='o'/>
          <div className='oAuth-container'>
            <GoogleButton
                buttonImage='/google-logo-search-new-svgrepo-com 1.png'
                buttonText='Continuar con Google'
                handleOnClick={() => handleOnOAuthClick('google')}
            />
          </div>
          <label>¿No tienes cuenta? <a href='/registrar' style={{ color: '#1369CA', textDecoration:'none'}}>Registrate</a></label>
        </div>
    </div>
  );
};

export default Login;